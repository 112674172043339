import {createRouter, createWebHistory} from 'vue-router'
import WelcomePage from "@/components/WelcomePage";
import MainLayout from "@/views/layout/MainLayout";


const routes = [
    {
        path: '/',
        component: MainLayout,
        children: [
            {
                path: '/',
                component: () => import("@/components/AdminPage")
            },
            {
                path: '/video',
                component: () => import("@/components/VideoPage")
            },
            {
                path: '/audio_part',
                component: () => import("@/components/AudioPartPage")
            },
            {
                path: '/page_ws',
                component: () => import("@/components/WSPage")
            }
        ]
    },
    {
        path: '/plugin',
        component: MainLayout,
        children: [
            {
                path: 'video',
                component: () => import("@/components/plugin/VideoPluginPage")
            },
            {
                path: 'cmd',
                component: () => import("@/components/plugin/CmdPluginPage")
            }
        ]
    },
    {
        path: '/chat',
        component: MainLayout,
        children: [
            {
                path: 'illusion',
                component: () => import("@/components/chat/IllusionPage")
            },
            {
                path: 'emotion',
                component: () => import("@/components/chat/ChatEmoPage")
            },
            {
                path: 'cluster',
                component: () => import("@/components/chat/ClusterPage")
            },
            {
                path: 'chat_history',
                component: () => import("@/components/chat/ChatHistory")
            }
        ]
    },
    {
        path: '/vrole',
        component: MainLayout,
        children: [
            {
                path: 'effect',
                component: () => import("@/components/vrole/EffectPage")
            },
            {
                path: 'vrole',
                component: () => import("@/components/vrole/VRolePage")
            },
            {
                path: 'role_effect/:role_id',
                component: () => import("@/components/vrole/RoleEffectPage"),
                props: true
            },
            {
                path: 'preset/:role_id',
                component: () => import("@/components/vrole/PresetPlayPage"),
                props: true
            },
        ]
    },
    {
        path: '/nbdoll',
        component: MainLayout,
        children: [
            {
                path: 'fw',
                component: () => import("@/components/nbdoll/FWPage")
            },
            {
                path: 'spicy',
                component: () => import("@/components/nbdoll/SpicyPage")
            },
            {
                path: 'app',
                component: () => import("@/components/nbdoll/APPPage")
            },
            {
                path: 'whitelist',
                component: () => import("@/components/nbdoll/WhiteListPage")
            },
            {
                path: 'ttslist',
                component: () => import("@/components/nbdoll/TTSPage")
            },
            {
                path: 'rolelist',
                component: () => import("@/components/nbdoll/RolePage")
            },
            {
                path: 'nbdoll',
                component: () => import("@/components/nbdoll/NBDollPage")
            },
            {
                path: 'character',
                component: () => import("@/components/nbdoll/CharacterPage")
            },
            {
                path: 'chat/prompt',
                component: () => import("@/components/nbdoll/chat/PromptPage")
            },
            {
                path: 'chat/panting',
                component: () => import("@/components/nbdoll/chat/PantingPage")
            },
            {
                path: 'chat/dialog',
                component: () => import("@/components/nbdoll/chat/DialogPage")
            },
            {
                path: 'chat/draft',
                component: () => import("@/components/nbdoll/chat/DraftPage")
            },
            {
                path: 'banner',
                component: () => import("@/components/nbdoll/BannerPage")
            },
            {
                path: 'manu/produce',
                component: () => import("@/components/nbdoll/manu/DeviceProducePage")
            },
            {
                path: 'manu/batch_produce',
                component: () => import("@/components/nbdoll/manu/BatchProducePage")
            },
        ]
    },
    {
        path: '/common',
        component: MainLayout,
        children: [
            {
                path: 'qa',
                component: () => import("@/components/common/QAPage")
            },
            {
                path: 'sw',
                component: () => import("@/components/common/SWPage")
            },
            {
                path: 'sw_reply',
                component: () => import("@/components/common/SWReplyPage")
            },
            {
                path: 'ins_event',
                component: () => import("@/components/common/InsEventPage")
            },
            {
                path: 'press_data',
                component: () => import("@/components/common/PressDataPage")
            },
            {
                path: 'skin_log',
                component: () => import("@/components/SkinLog")
            },
            {
                path: 'upload',
                component: () => import("@/components/common/UploadPage")
            },
            {
                path: 'ai_tool',
                component: () => import("@/components/common/AiToolPage")
            },
            {
                path: 'wake_word',
                component: () => import("@/components/common/WakeWordPage")
            }
        ]
    },
    {
        path: '/login',
        component: () => import("@/components/LoginPage")
    },
    {
        path: '/demo/dipper',
        component: () => import("@/components/demo/Dipper")
    },
    {
        path: '/chat',
        component: WelcomePage
    }
]

const router = createRouter({
        history: createWebHistory(),
        routes
    }
)
export default router
